.footer {
  background-color: #333333;
  /* border-top: 4px solid #666666; */
  color: #aaaaaa;
  /*font-size: 14px;*/
  /* line-height: 1.5; */
  /* margin-left: -32px;
          margin-right: -32px; */
  padding-bottom: 16px;
  padding-top: 16px;
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  /*
  padding-left: 32px;
  padding-right: 32px;
  */
}

.columns {
  display: flex;
  /*margin: 0px -32px;*/
}

.column {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0px 32px;
}

.footer h2 {
  font-size: 20px;
  font-weight: 500;
}

.footer a {
  color: #cccccc;
  text-decoration-color: #cccccc30;
}

.footer a:hover {
  background-color: #000000;
  color: #ffffff;
  text-decoration-color: #cccccc30;
}

.footer p {
  /*color: #aaaaaa;*/
}

.footer ul {
  /*color: #aaaaaa;*/
  padding: 0px;
  line-height: 1.6;
  list-style: none;
  margin: 16px 0px;
  /* Paragraph (p) margin */
}

.footer ul li {
  line-height: 1.6;
  list-style: none;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .footer {
    /* With padding-bottom: 0px the background color disappears??? */
    /* Somehow related to p with margins extending outside of the footer */
    /*
    padding-bottom: 1px;
    padding-top: 0px;
    */
  }

  .columns {
    flex-direction: column;
  }

  .column {
    margin: 0px 16px;
  }
}
.header {
  background: #441144;
  height: 64px;
  line-height: 64px;
  margin: 0px;
  /* padding: 0px 32px; */
  /* margin-bottom: 32px; */
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  padding-left: 32px;
  padding-right: 32px;
}

.icon {
  float: left;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 8px;
  display: block;
}

.title {
  color: #ffffff;
  float: left;
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  line-height: 64px;
}

.nav {
  float: right;
  line-height: 64px;
  margin: 0px;

  /* Adding some more margin to account for hamburger menu */
  margin-right: 64px;
}

@media (max-width: 800px) {
  .nav {
    display: none;
  }
}

.nav ul {
  display: inline;
  line-height: 64px;
  margin: 0px;
}

.nav li {
  display: inline;
  line-height: 64px;
  padding-left: 16px;
}

.nav a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  /*
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #f0f0f0;
  text-decoration: none;
  background-color: transparent !important;
  */
}

.title a,
.nav a {
  background-color: transparent !important;
  color: #f0f0f0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  /*font-size: 30px;*/
  text-decoration: none;
}

.title a:focus,
.nav a:focus {
  background-color: #66497b !important;
}

.title a.selected,
.nav a.selected {
  background-color: #66497b !important;
  color: white;
}

.title a:hover,
.nav a.selected:hover,
.nav a:hover {
  background-color: #917aa380 !important;
}

.title a:active,
.nav a:active {
  background-color: #917aa3a0 !important;
}

/* ------------------------------------------------------------------------- */

@media (max-width: 600px) {
  .content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
